import { getRumInstance } from "./singleton";

const rumInstance = getRumInstance();

export { init } from "./init";
export type { InitOptions } from "./types";
export const {
  addAction,
  addError,
  addTiming,
  setUser,
  clearUser,
  setUserProperty,
  removeUserProperty,
  setGlobalContextProperty,
  removeGlobalContextProperty,
  startView,
} = rumInstance;
