export const throttledSampleRate = 40;

export const environments = {
  unknown: "unknown",
  staging: "staging",
  production: "production",
  prodops: "prodops",
  demo: "demo",
  test: "test",
  development: "development",
  cit: "cit",
  beta: "beta",
} as const;

export const throttledEnvs: readonly string[] = [
  environments.production,
  environments.prodops,
  environments.demo,
  environments.cit,
];

export const unObservedEnvs: readonly string[] = [environments.beta, environments.staging];
