import { datadogRum, type RumGlobal, type RumInitConfiguration } from "@datadog/browser-rum";
import { JsqRumGlobalThis } from "./types";

declare let globalThis: JsqRumGlobalThis;

export function getRumInstance() {
  if (globalThis.__jsqDDRum__) {
    return globalThis.__jsqDDRum__;
  }

  const jsqRum: RumGlobal = {
    ...datadogRum,
    init: (initConfiguration: RumInitConfiguration) => {
      // make sure init can only be called once
      if (globalThis.__jsqDDRumInitialized__) return;
      datadogRum.init(initConfiguration);
      globalThis.__jsqDDRumInitialized__ = true;
    },
  };
  return (globalThis.__jsqDDRum__ = jsqRum);
}
