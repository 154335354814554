import { environments } from "./constants";

const ipAddressExp = /^(?:\d{1,3}\.){3}\d{1,3}$/;
const previewExp = /^pr[a-zA-Z\-_]+\d+$/;
const betaExp = /^beta\d+$/;

export function identifyEnvironment(): string {
  const { hostname } = location;

  if (["development", "test"].includes(process.env.NODE_ENV ?? "")) {
    if (hostname === "localhost" || ipAddressExp.test(hostname)) {
      return environments.development;
    }
  }

  const splitHostname = hostname.split(".");
  if (splitHostname.length < 3) {
    return environments.unknown;
  }

  const maybeEnv = splitHostname.at(-3);

  switch (maybeEnv) {
    case "cit":
      return environments.cit;
    case "prodops":
      return environments.prodops;
    case "demo":
      return environments.demo;
    case "staging":
      return environments.staging;
    case "test":
      return environments.test;
    case "dev":
    case "local":
      return environments.development;
    case "app":
    case "admin":
      return environments.production;
  }

  if (maybeEnv?.match(previewExp)) {
    // preview urls have the format <subdomain>.pr-<prNumber>.junipersquare.us
    // ipe urls have the format <subdomain>.pr<servicePrefix><prNumber>.junipersquare.us
    // servicePrefix can include dashes but not numbers
    //
    return maybeEnv;
  }

  if (maybeEnv?.match(betaExp)) {
    // beta urls have the format <subdomain>.beta<envNumber>.junipersquare.us
    // we don't init RUM in beta envs, so we just return 'beta'
    return environments.beta;
  }

  if (splitHostname.length === 3) return environments.production;
  return environments.unknown;
}
