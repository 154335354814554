import { environments, throttledEnvs, unObservedEnvs, throttledSampleRate } from "./constants";
import { filterEvents } from "./eventFiltering";
import { identifyEnvironment } from "./identifyEnv";
import { getRumInstance } from "./singleton";
import { InitOptions } from "./types";

/**
 * This will initialize Datadog RUM with the passed in parameters.
 * The environment will be automatically identified and RUM will be initialized
 * based on the environment.
 */
export function init(options: InitOptions) {
  // never init datadog rum in tests
  if (process.env.NODE_ENV === "test") {
    return;
  }

  const env = identifyEnvironment();

  // don't init in beta or staging
  if (unObservedEnvs.includes(env)) {
    return;
  }

  // don't init in local env unless debug flag is set
  if (env === environments.development) {
    const isDebugMode = process.env.DEBUG?.includes("@jsq/observability") ?? false;
    if (!isDebugMode && !options.localDevInitOverride) {
      return;
    }
  }

  // sample at 100% in non-prod environments
  const samplingRate = throttledEnvs.includes(env) ? throttledSampleRate : 100;

  getRumInstance().init({
    silentMultipleInit: true,
    applicationId: options.applicationId,
    clientToken: options.clientToken,
    service: options.serviceName,
    env,
    version: options.version,
    sessionSampleRate: samplingRate,
    // all outgoing requests in sampled sessions will be tracked
    traceSampleRate: 100,
    // no session replays
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    compressIntakeRequests: true,
    allowedTracingUrls: [
      (url) => new URL(url).hostname.endsWith(".junipersquare.com"),
      (url) => new URL(url).hostname.endsWith(".junipersquare.us"),
    ],
    beforeSend: filterEvents,
  });
}
